import { gql, useQuery } from "@apollo/client";

const GET_CLINIC_FEATURE_FLAGS = gql`
  query Clinic($clinicId: ID!) {
    clinic(id: $clinicId) {
      featureFlags
    }
  }
`;

interface GetFeatureFlagsResponse {
  clinic: {
    __typename: "Clinic";
    featureFlags: Array<string>;
  };
}

export function useGetClinicFeatureFlags(clinicId: number | string) {
  const { data } = useQuery<GetFeatureFlagsResponse, { clinicId: string | number }>(
    GET_CLINIC_FEATURE_FLAGS,
    {
      variables: {
        clinicId: clinicId,
      },
      skip: clinicId === -1,
    },
  );

  return { featureFlags: data?.clinic.featureFlags };
}
