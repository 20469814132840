import { ApolloClient, NormalizedCacheObject } from "@apollo/client";
import { legacyClient, titanClient } from "../client";

let _legacyClient: ApolloClient<NormalizedCacheObject>;
export function getLegacyApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!_legacyClient) {
    _legacyClient = legacyClient;
  }
  return _legacyClient;
}

let _titanClient: ApolloClient<NormalizedCacheObject>;

export function getTitanApolloClient(): ApolloClient<NormalizedCacheObject> {
  if (!_titanClient) {
    _titanClient = titanClient;
  }

  return _titanClient;
}
