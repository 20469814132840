export function assertNotNull<T>(value?: T | null, errorMessage = "Condition cannot be null"): T {
  if (value == null) throw new Error(errorMessage);

  return value;
}

export const pluralize = (count: number, noun: string, suffix = "s") =>
  `${count} ${noun}${count !== 1 ? suffix : ""}`;

export const generateId = () => Math.random().toString(36).substring(7);
