import { ToastClose } from "./ToastClose";
import { ToastAction } from "./ToastAction";
import { ToastDescription } from "./ToastDescription";
import { ToastProvider } from "./ToastProvider";
import { ToastRoot } from "./ToastRoot";
import { ToastTitle } from "./ToastTitle";
import { ToastViewport } from "./ToastViewport";

export const Toast = {
  Action: ToastAction,
  Provider: ToastProvider,
  Toast: ToastRoot,
  Description: ToastDescription,
  Title: ToastTitle,
  Viewport: ToastViewport,
  Close: ToastClose,
};
