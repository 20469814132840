import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

type DialogTriggerProps = {
  children: React.ReactNode;
};

export const DialogTrigger = ({ children }: DialogTriggerProps) => {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;
};
