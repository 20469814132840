import * as DialogPrimitive from "@radix-ui/react-dialog";
import React from "react";
import { clsxm } from "src/utils/clsxWithTailwindMerge";

type DialogTitleProps = {
  children: React.ReactNode;
  className?: string;
  asChild?: boolean;
};

export const DialogTitle = ({ children, className, asChild = false }: DialogTitleProps) => {
  return (
    <DialogPrimitive.Title
      asChild={asChild}
      className={clsxm("mb-1 text-lg font-medium", className)}>
      {children}
    </DialogPrimitive.Title>
  );
};
