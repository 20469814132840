import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";
import { ReactNode } from "react";

type DialogRootProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  children: ReactNode;
};

export const DialogRoot = ({ children, open, ...props }: DialogRootProps) => {
  if (!open) return null;
  return (
    <DialogPrimitive.Root open={open} {...props}>
      <div
        className={clsx({
          "fixed top-0 left-0 right-0 bottom-0 z-20 flex flex-col items-center justify-center":
            !!open,
        })}>
        {children}
      </div>
    </DialogPrimitive.Root>
  );
};
