import { gql, useQuery } from "@apollo/client";

const GET_CLINIC_BY_ID = gql`
  query clinic($id: ID!) {
    clinic(id: $id) {
      id
      name
      domain
      metadata
      featureFlags
    }
  }
`;

type IGetClinicByIdResponse = {
  clinic: {
    id: string;
    name: string;
    domain: string;
    metadata: string;
    featureFlags?: string[];
  };
};

type IGetClinicByIdVariables = {
  id?: string;
};

export const useGetClinicById = (id?: string) => {
  return useQuery<IGetClinicByIdResponse, IGetClinicByIdVariables>(GET_CLINIC_BY_ID, {
    variables: {
      id,
    },
    skip: !id,
  });
};
