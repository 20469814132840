import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";

type ToastDescriptionProps = {
  children: React.ReactNode;
};

export const ToastDescription = ({ children }: ToastDescriptionProps) => {
  return (
    <ToastPrimitive.Title asChild>
      <span
        className={"mt-2.5 leading-tight text-gray-600"}
        style={{ gridArea: "toastDescription" }}>
        {children}
      </span>
    </ToastPrimitive.Title>
  );
};
