import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";

export const ToastViewport = () => {
  return (
    <ToastPrimitive.Viewport
      className={
        "fixed top-0 right-0 m-0 flex w-96 max-w-[100vw] list-none flex-col gap-10 p-6 outline-none"
      }
    />
  );
};
