import React from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

type ToastCloseProps = {
  children: React.ReactNode;
};

export const ToastClose = ({ children }: ToastCloseProps) => {
  return <ToastPrimitive.Close asChild>{children}</ToastPrimitive.Close>;
};
