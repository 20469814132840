import React from "react";
import { EmptyState } from "../EmptyState";
import { IoAlertCircle } from "react-icons/io5";
import { useRouter } from "next/router";

interface ErrorStateArgs {
  error: Error;
}

export function ErrorState({ error }: ErrorStateArgs) {
  return (
    <EmptyState
      customIcon={<IoAlertCircle size={100} name="alert-circle" className="text-pink2" />}
      title="We had an unexpected error"
      text={error.message}
      bottomAccessory={
        <div className="mt-6 flex flex-col items-center gap-2">
          <p className="text-sm text-darkGrey1">
            If the error persists please contact our support.
          </p>
        </div>
      }
    />
  );
}

export function ErrorStateWithErrorCode({
  code,
  title = "We had an unexpected problem",
  subtitle = "Please try refreshing the page",
}: {
  title?: string;
  subtitle?: string;
  code: string;
}) {
  const router = useRouter();
  return (
    <EmptyState
      customIcon={<IoAlertCircle size={100} name="alert-circle" className="text-pink2" />}
      title={title}
      text={subtitle}
      bottomAccessory={
        <div className="mt-6 flex flex-col items-center gap-2">
          <p className="text-sm text-darkGrey1">
            If the error persists please contact support mentioning this error code:
          </p>
          <div className="rounded border-[1px] border-grey2 bg-grey1 px-3 py-1 text-sm">
            <p className="text-sm font-medium text-darkGrey2">{code}</p>
          </div>
          <button
            onClick={() => router.push("/dashboard")}
            className="h-8 rounded-[20px] border-transparent bg-pink6 px-3 text-[15px] font-light text-white">
            Go to patient list
          </button>
        </div>
      }
    />
  );
}
