import Image, { StaticImageData } from "next/image";
import React from "react";
import balloonPic from "../../../../public/images/balloon.png";

const ICON_SIZE = {
  xs: 60,
  sm: 100,
  md: 150,
};

interface EmptyStateProps {
  iconName?: StaticImageData;
  customIcon?: React.ReactNode;
  title: string;
  text: React.ReactNode;
  bottomAccessory?: React.ReactNode;
  size?: keyof typeof ICON_SIZE;
}

export const EmptyState = ({
  iconName = balloonPic,
  title,
  text,
  bottomAccessory,
  size = "md",
  customIcon,
}: EmptyStateProps) => {
  const iconSize = ICON_SIZE[size];

  return (
    <div className="my-3 flex flex-1 flex-col items-center justify-center">
      <div className={`my-5 items-center justify-center w-[${iconSize}px] h-[${iconSize}px]`}>
        {customIcon || <Image alt={"Empty"} src={iconName} height="80" width="80" />}
      </div>
      <p className="text-base font-medium">{title}</p>
      <p className="text-base text-darkGrey1">{text}</p>
      {bottomAccessory}
    </div>
  );
};
