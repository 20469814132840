import { noop } from "lodash";
import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { legacyClient, titanClient } from "src/backend/client";
import { setAuthToken, setPermissions, setRefreshToken } from "src/backend/helpers/authToken";
import { featureFlagOptions } from "src/consts/featureFlags";
import { useGetClinicFeatureFlags } from "src/data/queries/useGetClinicFeatureFlags";
import { IGetClinicianResponse, useGetClinician } from "src/data/queries/useGetClinician";
import { useAnalytics } from "src/hooks/useAnalytics";
import mixpanel from "mixpanel-browser";
import { useIntercom } from "react-use-intercom";
import config from "../config";
import { useRouter } from "next/router";

const ActiveClinicianContext = createContext<{
  activeClinician: IGetClinicianResponse["getClinician"] | null | undefined;
  fetchActiveClinician: () => Promise<IGetClinicianResponse["getClinician"] | null>;
  logOut: () => void;
}>({
  activeClinician: undefined,
  fetchActiveClinician: noop as () => Promise<IGetClinicianResponse["getClinician"] | null>,
  logOut: noop,
});

export const ActiveClinicianContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [activeClinician, setActiveClinician] = useState<
    IGetClinicianResponse["getClinician"] | null | undefined
  >();
  const { getClinician } = useGetClinician();
  const { featureFlags } = useGetClinicFeatureFlags(activeClinician?.clinic.id || -1);
  const { setUserProperties } = useAnalytics(mixpanel);
  const { shutdown: shutdownIntercom, boot } = useIntercom();
  const router = useRouter();

  const fetchActiveClinician = useCallback(async () => {
    try {
      const activeClinician = await getClinician();
      if (activeClinician.data) {
        setActiveClinician(activeClinician.data.getClinician);
        if (activeClinician) setUserProperties(activeClinician.data.getClinician);
      }
      return activeClinician.data?.getClinician || null;
    } catch (e) {
      setActiveClinician(null);
      return null;
    }
  }, [getClinician, setUserProperties]);

  const logOut = useCallback(async () => {
    setActiveClinician(null);
    setAuthToken(undefined);
    setPermissions(undefined);
    setRefreshToken(undefined);
    shutdownIntercom();
    await titanClient.clearStore();
    await legacyClient.clearStore();
    router.push("/login");
  }, [router, shutdownIntercom]);

  useEffect(() => {
    if (
      activeClinician &&
      featureFlags?.includes(featureFlagOptions.ENABLE_INTERCOM_THERAPIST_APP)
    ) {
      boot({
        userId: activeClinician.id,
        name: `${activeClinician.name} (Clinician)`,
        email: activeClinician.email,
        hideDefaultLauncher: !featureFlags?.includes(
          featureFlagOptions.SHOW_INTERCOM_THERAPIST_APP,
        ),
        horizontalPadding: 120,
        verticalPadding: 40,
        customAttributes: {
          user_type: "clinician",
          user_clinic: activeClinician.clinic.name,
          user_clinicId: activeClinician.clinic.id,
          environment: config.stage,
        },
      });
    }
  }, [activeClinician, boot, featureFlags]);

  return (
    <ActiveClinicianContext.Provider value={{ activeClinician, fetchActiveClinician, logOut }}>
      {children}
    </ActiveClinicianContext.Provider>
  );
};

export const useActiveClinicianContext = () => useContext(ActiveClinicianContext);
