import { assertNotNull } from "./utils";

const config = {
  stage: process.env.NODE_ENV || "development",
  refreshTokenEndpoint: assertNotNull(
    process.env.NEXT_PUBLIC_REFRESH_TOKEN_ENDPOINT,
    "NEXT_PUBLIC_REFRESH_TOKEN_ENDPOINT env variable is mandatory",
  ),
  loginEndpoint: assertNotNull(
    process.env.NEXT_PUBLIC_LOGIN_ENDPOINT,
    "NEXT_PUBLIC_LOGIN_ENDPOINT env variable is mandatory",
  ),
  titanEndpoint: assertNotNull(
    process.env.NEXT_PUBLIC_TITAN_ENDPOINT,
    "NEXT_PUBLIC_TITAN_ENDPOINT env variable is mandatory",
  ),
  titanWsEndpoint: assertNotNull(
    process.env.NEXT_PUBLIC_WS_TITAN_ENDPOINT,
    "NEXT_PUBLIC_WS_TITAN_ENDPOINT env variable is mandatory",
  ),
  legacyGraphqlEndpoint: assertNotNull(
    process.env.NEXT_PUBLIC_LEGACY_GRAPHQL_ENDPOINT,
    "NEXT_PUBLIC_LEGACY_GRAPHQL_ENDPOINT env variable is mandatory",
  ),
  sentrySecret: assertNotNull(
    process.env.NEXT_PUBLIC_SENTRY_SECRET,
    "NEXT_PUBLIC_SENTRY_SECRET env variable is mandatory",
  ),
  IS_INTERVENTION_BUILDER_ENABLED: false,
  IS_SIGNUP_ENABLED: false,
  ARE_REMINDERS_ENABLED: true,
  REFRESH_TOKEN_MAX_AGE: 60 * 60 * 24 * 30, // 30 days
  internalAuthEndpoint: "/api/auth",
  internalGqlEndpoint: process.env.NEXT_PUBLIC_TITAN_ENDPOINT,
  mixpanelToken: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
  intercomAppId: process.env.NEXT_PUBLIC_INTERCOM_APP_ID,
};

export default config;
