const AUTH_TOKEN_KEY = "@limbic-for-therapists/auth-token";
const REFRESH_TOKEN_KEY = "@limbic-for-therapists/refresh-token";
const USER_TYPE_KEY = "@limbic-for-therapists/user-permissions";

const getAuthToken = () => {
  if (!window) return null;
  return window.localStorage.getItem(AUTH_TOKEN_KEY);
};

const getRefreshToken = () => {
  if (!window) return null;
  return window.localStorage.getItem(REFRESH_TOKEN_KEY);
};

const setAuthToken = (authToken: string | undefined) => {
  if (!authToken) return window.localStorage.removeItem(AUTH_TOKEN_KEY);
  return window.localStorage.setItem(AUTH_TOKEN_KEY, authToken);
};

const setPermissions = (permissions: string[] | undefined) => {
  if (!permissions) return window.localStorage.removeItem(USER_TYPE_KEY);
  return window.localStorage.setItem(USER_TYPE_KEY, JSON.stringify(permissions));
};

const setRefreshToken = (refreshToken: string | undefined) => {
  if (!refreshToken) return window.localStorage.removeItem(REFRESH_TOKEN_KEY);
  return window.localStorage.setItem(REFRESH_TOKEN_KEY, JSON.stringify(refreshToken));
};

const getPermissions = (): string[] => {
  try {
    if (!window) throw new Error();
    const permissions = window.localStorage.getItem(USER_TYPE_KEY);
    if (!permissions) throw new Error();
    return JSON.parse(permissions);
  } catch {
    return [];
  }
};

export { getAuthToken, getRefreshToken, setAuthToken, setPermissions, setRefreshToken };
