import * as DialogPrimitive from "@radix-ui/react-dialog";
import React from "react";

type DialogDescriptionProps = {
  children: React.ReactNode;
  className?: string;
};

export const DialogDescription = ({ children, className }: DialogDescriptionProps) => {
  return (
    <DialogPrimitive.Description className={className} asChild>
      <p className={"mt-2.5 leading-tight text-gray-500"}>{children}</p>
    </DialogPrimitive.Description>
  );
};
