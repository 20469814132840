import React from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

type ToastProviderProps = {
  children: React.ReactNode;
};

export const ToastProvider = ({ children }: ToastProviderProps) => {
  return <ToastPrimitive.Provider>{children}</ToastPrimitive.Provider>;
};
