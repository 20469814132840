import React from "react";
import * as DialogPrimitive from "@radix-ui/react-dialog";

type DialogCloseProps = {
  children: React.ReactNode;
};

export const DialogClose = ({ children }: DialogCloseProps) => {
  return <DialogPrimitive.Close asChild>{children}</DialogPrimitive.Close>;
};
