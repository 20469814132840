import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { IClinician } from "../models/IClinician";
import { getLegacyApolloClient } from "src/backend/helpers/getApolloClient";

const GET_CLINICIAN = gql`
  query getClinician {
    getClinician(includeClinicInfo: true) {
      id
      name
      email
      clinic {
        id
        name
      }
    }
  }
`;

interface IGetClinicianClinician extends Pick<IClinician, "id" | "name" | "email"> {
  clinic: {
    id: string;
    name: string;
  };
}

export interface IGetClinicianResponse {
  getClinician: IGetClinicianClinician;
}

export const useGetClinician = () => {
  const [getClinician, { refetch, data: activeClinician }] = useLazyQuery<IGetClinicianResponse>(
    GET_CLINICIAN,
    {
      client: getLegacyApolloClient(),
    },
  );
  return { getClinician, refetch, activeClinician };
};
