import clsx from "clsx";
import React from "react";
import { IoArrowBackOutline, IoCloseOutline } from "react-icons/io5";
import { Dialog } from "src/components/rough/Dialog";

const MODAL_MAX_HEIGHT = "98%";

type ModalContainerProps = {
  children: React.ReactNode | undefined;
  dismissModal: () => void;
  showCloseButton?: boolean;
  customWidth?: number | string;
};

const ModalTitle = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <h3 className={clsx(["text-2xl font-light text-darkGrey2"], className)}>{children}</h3>;
};

const ModalBody = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={clsx(["px-1 py-2 font-light"], className)}>{children}</div>;
};

const ModalInnerContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div
      className={clsx(
        ["no-scrollbar my-4 flex min-h-0 flex-col overflow-y-auto overflow-x-hidden px-6"],
        className,
      )}>
      {children}
    </div>
  );
};

export const ModalContainer = ({
  children,
  dismissModal,
  showCloseButton = true,
  customWidth,
}: ModalContainerProps) => {
  return (
    <Dialog.Dialog open={!!children} onOpenChange={() => dismissModal()}>
      <Dialog.Content
        showCloseButton={showCloseButton}
        contentClassName={`w-[${
          customWidth ? customWidth + "px" : "auto"
        }] bg-transparent p-0 max-h-[${MODAL_MAX_HEIGHT}] therapist-font rounded-2xl pb-4 max-w-[700px] flex flex-col items-center`}>
        <div className="flex min-h-0 w-11/12 min-w-[400px] flex-col items-stretch overflow-hidden rounded-2xl bg-white">
          {showCloseButton && (
            <Dialog.Title className="bg-purple p-5">
              <div className="flex justify-between rounded-tl-2xl rounded-tr-2xl text-white">
                <Dialog.Close>
                  <button>
                    <IoArrowBackOutline size={24} />
                  </button>
                </Dialog.Close>

                <Dialog.Close>
                  <button>
                    <IoCloseOutline size={24} />
                  </button>
                </Dialog.Close>
              </div>
            </Dialog.Title>
          )}
          {children}
        </div>
      </Dialog.Content>
    </Dialog.Dialog>
  );
};

ModalContainer.Title = ModalTitle;
ModalContainer.Body = ModalBody;
ModalContainer.InnerContainer = ModalInnerContainer;
