import * as DialogPrimitive from "@radix-ui/react-dialog";
import clsx from "clsx";
import React from "react";
import { X } from "react-feather";
import { clsxm } from "src/utils/clsxWithTailwindMerge";

type DialogContentProps = {
  children: React.ReactNode;
  contentClassName?: string;
  showCloseButton?: boolean;
};

export const DialogContent = ({
  children,
  contentClassName,
  showCloseButton = true,
}: DialogContentProps) => {
  return (
    <>
      <DialogPrimitive.Overlay
        className={"fixed bottom-0 left-0 right-0 top-0 z-[11] bg-black opacity-40"}
      />
      <DialogPrimitive.Content asChild className={clsxm("bg-white p-6", contentClassName)}>
        <div
          className={clsx(
            `fixed z-[12] flex animate-modal-show flex-col rounded-md max-w-screen-sm`,
            contentClassName,
          )}>
          {children}
          {showCloseButton && (
            <DialogPrimitive.Close asChild>
              <button
                className={
                  "absolute right-4 top-6 flex h-7 w-7 cursor-pointer items-center justify-center rounded-md bg-transparent p-1 text-gray-900 transition duration-200 ease-in-out hover:bg-gray-200"
                }>
                <X />
              </button>
            </DialogPrimitive.Close>
          )}
        </div>
      </DialogPrimitive.Content>
    </>
  );
};
