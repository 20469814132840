export interface DimensionType {
  width: number;
  height: number;
}

interface ResponsiveProps {
  currentSize: ResponsiveSize;
}

export const grid = {
  minWidth: 600,
  maxWidth: 1200,
  breakpoints: [768, 998, 1200],
};

export type ResponsiveSize = "xs" | "sm" | "md" | "lg";

const responsiveValue = <T extends unknown>(
  currentSize: ResponsiveSize,
  xs: T,
  sm?: T,
  md?: T,
  lg?: T,
) => {
  switch (currentSize) {
    case "xs":
      return xs;
    case "sm":
      return sm ?? xs;
    case "md":
      return md ?? sm ?? xs;
    case "lg":
      return lg ?? md ?? sm ?? xs;
    default:
      return xs;
  }
};
