import { ApolloClient, InMemoryCache, from, HttpLink, createHttpLink, split } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { GraphQLRequest } from "@apollo/client/link/core";
import { getAuthToken } from "./helpers/authToken";
import dayjs from "dayjs";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import config from "../config";

const cache = new InMemoryCache();
const titanCache = new InMemoryCache({
  typePolicies: {
    ISOInterval: {
      fields: {
        iso8601Duration: {
          read(value: string) {
            return dayjs.duration(value);
          },
        },
      },
    },
  },
});

const legacyHttpLink = new HttpLink({
  uri: config.legacyGraphqlEndpoint,
});

const titanHttpLink = createHttpLink({
  uri: config.titanEndpoint,
});

const authLink = setContext(async (_: GraphQLRequest, prevContext: Record<string, any>) => {
  const { headers } = prevContext;
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
  };
});

const titanAuthLink = setContext((_, { headers }) => {
  const token = getAuthToken();
  return {
    headers: {
      ...headers,
      ...(token
        ? {
            Authorization: `Bearer ${token}`,
          }
        : {}),
    },
  };
});

const wsLink = new GraphQLWsLink(
  createClient({
    url: config.titanWsEndpoint,
    connectionParams: () => {
      const token = getAuthToken();
      return {
        Authorization: token ? `Bearer ${token}` : "",
      };
    },
  }),
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === "OperationDefinition" && definition.operation === "subscription";
  },
  wsLink,
  titanAuthLink.concat(titanHttpLink),
);

export const legacyClient = new ApolloClient({
  name: "Limbic for Therapists",
  link: from([authLink, legacyHttpLink]),
  cache,
});

export const titanClient = new ApolloClient({
  name: "New Care Backend Titan Server",
  link: splitLink,
  cache: titanCache,
});
