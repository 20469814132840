import React from "react";
import * as ToastPrimitive from "@radix-ui/react-toast";

type ToastActionProps = {
  altText: string;
  children: React.ReactNode;
};

export const ToastAction = ({ altText, children }: ToastActionProps) => {
  return (
    <ToastPrimitive.Action altText={altText} asChild>
      {children}
    </ToastPrimitive.Action>
  );
};
