export const featureFlagOptions = {
  DISABLE_INVITE_FLOW: "disable_invite_flow",
  DISABLE_CHANGE_DATE: "disable_change_date",
  DISABLE_QUESTIONNAIRES: "disable_questionnaires",
  DISABLE_NEXT_SESSION: "disable_next_session",
  DISABLE_TODAY_TAB: "disable_today_tab",
  DISABLE_PATIENT_CARD_TAGS: "disable_patient_card_tags",
  ALPHA: "alpha",
  EXTERNAL_QUESTIONNAIRE_SERVICE: "external_questionnaire_service",
  DISABLE_RECURRING_SESSION_SCHEDULE: "disable_recurring_session_schedule",
  DISABLE_PATIENT_EDIT: "disable_patient_edit",
  ENABLE_INTERCOM_THERAPIST_APP: "enable_intercom_therapist_app",
  SHOW_INTERCOM_THERAPIST_APP: "show_intercom_therapist_app",
  ENABLE_NEW_PATIENT_INVITE_FLOW: "enable_new_patient_invite_flow",
  FETCH_YEAR_BEFORE_DATA_ON_SUMMARY_FIRST_LOAD: "fetch_year_before_data_on_summary_first_load",
  DISABLE_NOTES_SUMMARY_STREAMING: "disable_notes_summary_streaming",
};

type FeatureFlag = keyof typeof featureFlagOptions;

export type FeatureFlags = { [key in FeatureFlag]: boolean };

export const getFeatureFlags = (featureFlagsArr?: string[] | null): FeatureFlags => {
  const flagsArray = featureFlagsArr || [];
  return {
    DISABLE_INVITE_FLOW: flagsArray.includes(featureFlagOptions.DISABLE_INVITE_FLOW),
    DISABLE_CHANGE_DATE: flagsArray.includes(featureFlagOptions.DISABLE_CHANGE_DATE),
    DISABLE_QUESTIONNAIRES: flagsArray.includes(featureFlagOptions.DISABLE_QUESTIONNAIRES),
    DISABLE_NEXT_SESSION: flagsArray.includes(featureFlagOptions.DISABLE_NEXT_SESSION),
    DISABLE_TODAY_TAB: flagsArray.includes(featureFlagOptions.DISABLE_TODAY_TAB),
    DISABLE_PATIENT_CARD_TAGS: flagsArray.includes(featureFlagOptions.DISABLE_PATIENT_CARD_TAGS),
    ALPHA: flagsArray.includes(featureFlagOptions.ALPHA),
    EXTERNAL_QUESTIONNAIRE_SERVICE: flagsArray.includes(
      featureFlagOptions.EXTERNAL_QUESTIONNAIRE_SERVICE,
    ),
    DISABLE_RECURRING_SESSION_SCHEDULE: flagsArray.includes(
      featureFlagOptions.DISABLE_RECURRING_SESSION_SCHEDULE,
    ),
    DISABLE_PATIENT_EDIT: flagsArray.includes(featureFlagOptions.DISABLE_PATIENT_EDIT),
    ENABLE_INTERCOM_THERAPIST_APP: flagsArray.includes(
      featureFlagOptions.ENABLE_INTERCOM_THERAPIST_APP,
    ),
    SHOW_INTERCOM_THERAPIST_APP: flagsArray.includes(
      featureFlagOptions.SHOW_INTERCOM_THERAPIST_APP,
    ),
    ENABLE_NEW_PATIENT_INVITE_FLOW: flagsArray.includes(
      featureFlagOptions.ENABLE_NEW_PATIENT_INVITE_FLOW,
    ),
    FETCH_YEAR_BEFORE_DATA_ON_SUMMARY_FIRST_LOAD: flagsArray.includes(
      featureFlagOptions.FETCH_YEAR_BEFORE_DATA_ON_SUMMARY_FIRST_LOAD,
    ),
    DISABLE_NOTES_SUMMARY_STREAMING: flagsArray.includes(
      featureFlagOptions.DISABLE_NOTES_SUMMARY_STREAMING,
    ),
  };
};
