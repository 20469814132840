import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";

type ToastTitleProps = {
  children: React.ReactNode;
};

export const ToastTitle = ({ children }: ToastTitleProps) => {
  return (
    <ToastPrimitive.Title asChild>
      <div className={"m-0 text-lg font-medium text-gray-700"} style={{ gridArea: "toastTitle" }}>
        {children}
      </div>
    </ToastPrimitive.Title>
  );
};
