import * as ToastPrimitive from "@radix-ui/react-toast";
import React from "react";

type ToastRootProps = {
  open: boolean;
  onOpenChange: (value: boolean) => void;
  type?: "foreground" | "background";
  children: React.ReactNode[];
};

export const ToastRoot = ({
  open,
  onOpenChange,
  type = "foreground",
  children,
}: ToastRootProps) => {
  return (
    <ToastPrimitive.Root open={open} onOpenChange={onOpenChange} type={type} asChild>
      <div
        className={"grid rounded-md border border-gray-300 bg-white p-3.5 font-sans"}
        style={{
          gridTemplateAreas: '"toastTitle toastAction" "toastDescription toastAction"',
          gridTemplateColumns: "auto max-content",
          columnGap: "1rem",
          alignItems: "center",
          boxShadow:
            "hsl(206 22% 7% / 16%) 0px 10px 38px -10px, hsl(206 22% 7% / 10%) 0px 10px 20px -15px",
        }}>
        {children}
      </div>
    </ToastPrimitive.Root>
  );
};
