import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import mixpanel from "mixpanel-browser";
import { NextPage } from "next";
import type { AppProps } from "next/app";
import localFont from "next/font/local";
import Head from "next/head";
import NextNProgress from "nextjs-progressbar";
import { ReactElement, ReactNode } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { IntercomProvider } from "react-use-intercom";
import { getTitanApolloClient } from "src/backend/helpers/getApolloClient";
import { ActiveClinicianContextProvider } from "src/context/ActiveClinicianContext";
import { ModalContextProvider } from "src/context/ModalContext";
import { ScreenDimensionsContextProvider } from "src/context/ScreenDimensionsContext";
import "src/styles/globals.css";
import { Frame } from "../components/rough/Frame";
import { Toast } from "../components/rough/Toast";
import config from "../config";
import { ErrorState } from "src/components/rough/ErrorState";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

const aeroport = localFont({
  src: [
    {
      path: "../../public/fonts/Aeroport-Light.ttf",
      weight: "300",
    },
    {
      path: "../../public/fonts/Aeroport.ttf",
      weight: "400",
    },
    {
      path: "../../public/fonts/Aeroport-Medium.ttf",
      weight: "500",
    },
    {
      path: "../../public/fonts/Aeroport-Bold.ttf",
      weight: "bold",
    },
  ],
  variable: "--font-aeroport",
});

const client = getTitanApolloClient();

export type Stage = "production" | "staging" | "development";

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  if (config.mixpanelToken) {
    mixpanel.init(config.mixpanelToken);
  }

  return (
    <Sentry.ErrorBoundary
      fallback={<ErrorState error={new Error("Please try refreshing the page")} />}>
      <ApolloProvider client={client}>
        <DndProvider backend={HTML5Backend}>
          <Toast.Provider>
            <Frame>
              <Head>
                <title>Limbic Courses</title>
                <meta name="description" content="Limbic Courses" />
                <link rel="icon" href="/favicon.ico" />
                <style
                  dangerouslySetInnerHTML={{
                    __html: `
                  :root {
                    --font-aeroport: ${aeroport.style.fontFamily};
                  }`,
                  }}
                />
              </Head>
              <NextNProgress color="#EF5DA8" />
              <ToastContainer toastClassName={"shadow-xl rounded-xl px-4 m-4"} />
              <IntercomProvider
                appId={config.intercomAppId ?? ""}
                shouldInitialize={!!config.intercomAppId}>
                <ActiveClinicianContextProvider>
                  <ScreenDimensionsContextProvider>
                    <ModalContextProvider>
                      {getLayout(<Component {...pageProps} />)}
                    </ModalContextProvider>
                  </ScreenDimensionsContextProvider>
                </ActiveClinicianContextProvider>
              </IntercomProvider>
            </Frame>
            <Toast.Viewport />
          </Toast.Provider>
        </DndProvider>
      </ApolloProvider>
    </Sentry.ErrorBoundary>
  );
}

export default MyApp;
